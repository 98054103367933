.p-dialog {
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  // overflow: hidden; // commented to avoid hidden dropdowns panels inside dialogs

  &.p-dialog-maximized {
    left: 0 !important;
    top: 0 !important;
  }

  &.maximizable {
    width: 40vw;
  }

  .p-dialog-header {
    height: 48px;
    padding: 0.7rem 1.2rem !important;
    font-size: 16px;
    color: var(--ta-grid-dialog-header-text-color);
    border-bottom: 1px solid var(--ta-panel-header-border-color);
    background: var(--ta-grid-dialog-header-color);

    .p-dialog-header-close-icon, .p-dialog-header-maximize-icon {
      color: var(--ta-grid-dialog-header-text-color);
      &:hover{color: var(--ta-grid-dialog-header-text-hover-color);}
    }

    button {
      margin: 0 0.5em 0 0;
      width: 16px;
    }

    i.header.ta {
      margin-right: 3px;
    }
  }

  .p-dialog-content {
    padding: 30px 20px 20px 16px !important;
    overflow: auto;
    color: var(--ta-text-color);
    background-color: #ffffff;

    .html-panel {
      padding: 15px;

      &.pre-wrap {
        white-space: pre-wrap;
      }
    }
  }

  .p-dialog-footer {
    background-color: #ffffff;
    border-top: 1px solid var(--ta-panel-border-color);
    color: #333333;
    margin: 0;
    padding: 0.571em 1em;
    position: relative;
    text-align: right;
    top: -1px;

    button {
      margin: 0 0.5em 0 0;
    }
  }
}

.ui-dialog-mask, .p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1301 !important;
}
