.p-tabview {
  background: transparent;
  border: 0 none;
  padding: 0;

  .p-tabview-nav-container {
    position: relative;
    z-index: 1;

    .p-tabview-nav {
      background: transparent;
      border: 0 none;
      border-radius: 0;
      margin: 0;
      padding: 0;
    
      li {
        height: calc(38px - 5px);
        margin: 2px 2px 0 0;
        border: 1px solid var(--ta-tab-border-color);
        color: var(--ta-tab-text-color);
        outline: none;
        background: var(--ta-tab-background-color);

        a {
          height: 100%;
          padding: 0 15px;
          font-size: 15px;
          font-weight: normal;
          outline: none;

          span {padding-top: 2px;}
        }

        &:hover {
          color: var(--ta-tab-text-color-hover);
        } 
        
        &.p-highlight {
          height: 38px;
          margin-top: 0;
          color: var(--ta-tab-text-color-active);
          border: 1px solid var(--ta-tab-border-color-active);
          border-top: 2px solid var(--ta-primary-color);
          border-bottom: 0;
          background: var(--ta-tab-background-color-active);

          a {

            span {
              position: relative;
              top: -2px;
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  .p-tabview-panels {
    position: relative;
    top: -1px;
    border: 1px solid var(--ta-tab-body-border-color);
    background-color: var(--ta-tab-body-background-color);

    .p-tabview-panel{
      overflow: hidden;
      opacity: 1;
      transform: translateY(0);
      transition: opacity .8s, transform .3s; 
    }
    .p-tabview-panel[hidden]{
      display: block !important;
      height: 0;
      padding: 0 !important;
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}
