@import "../variables2";

.p-multiselect {
  max-width: 100%;
  min-height: $dropdown-xs-height;
  display: flex !important;
  background-color: var(--ta-white-color);
  border: 1px solid var(--ta-input-border-color);
  border-radius: 0 !important;
  font-size: $global-font-size;

  // &.p-focus {
  //   border-color: rgba(0, 0, 0, 0);
  //   box-shadow: 0 0 0 1px var(--ta-primary-color), 0 0 5px var(--ta-primary-color);
  // }

  &.multiselect-xxs {
    font-size: $multiselect-xxs-font;
    height: $dropdown-xxs-height !important;
    min-height: $dropdown-xxs-height !important;
    &.p-multiselect-label,
    .p-multiselect-label {
      line-height: $dropdown-xxs-height - 1;
      padding: $dropdown-xxs-padding;
      font-size: $dropdown-xxs-font;
    }
  }
  &.multiselect-xs {
    font-size: $multiselect-xs-font;
  }
  &.multiselect-s {    
    font-size: $multiselect-s-font;
  }
  &.multiselect-l {
    font-size: $multiselect-l-font;
  }
}

.p-multiselect-clear-icon {
  width: 9px;
  font-size: 9px;
  right: 28px;
  line-height: 15px;
}
.p-multiselect-label {
  margin-right: 10px !important;
}

.p-multiselect-trigger {
  width: 2em;
  color: $dropdown-trigger-color;
  background-color: var(--ta-light-color);
}

.p-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: var(--ta-white-color);
  box-shadow: var(--ta-dropdown-panel-shadow);
  margin-top: -2px; 

  .p-multiselect-header {
    color: #333333;
    background-color: #eceeef;
    border-bottom: 1px solid #eaeaea;
    margin: 0;
    padding: 6px 1rem;

    .p-checkbox {
      float: none;
      margin-right: 0.5em;
      vertical-align: middle;
    }

    .p-multiselect-filter-container {
      flex-grow: 1;
      margin-right: 8px;

      input {
        height: 24px;
        line-height: 24px;
        padding-right: 2em;
      }

      .p-multiselect-header {
        color: #333333;
        background-color: #eceeef;
        border-bottom: 1px solid #eaeaea;
        margin: 0;
        padding: 8px 10px;

        .p-checkbox {
          float: none;
          margin-right: 0.5em;
          vertical-align: middle;
        }

          &:focus {
            border-color: #007ad9;
            box-shadow: none;
            outline: 0 none;
          }
        } 

        .p-multiselect-filter-icon {
          color: #007ad9;
          right: .5em;
        }
    }

    .p-multiselect-close {
      color: #848484;
      font-size: 12px;
      outline: none;
    }
  }
  
  .p-multiselect-items-wrapper{
    max-height: $multiselect-dropdown-max-height;

    ul.p-multiselect-items {
      margin: 0;
      padding: 1px 0;
      
      li.p-multiselect-item {
        border-radius: 0;
        background: var(--ta-white-color);
        color: var(--ta-text-color);
        margin: 0;
        outline: none;
        padding: 3px 1rem;
        
        &:hover { background-color: var(--ta-dropdown-panel-item-hover); } 

        div.p-checkbox {
          display: inline-block;
          margin: 0 0.5em 0 0;
          margin-bottom: 2px;
          position: static;
          vertical-align: middle;

          .p-checkbox-box {
            .p-checkbox-icon {
            }
          }
        }

        span { 
          white-space: nowrap; 
          max-width: $multiselect-dropdown-item-max-width;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

      }
    }
  }

  // .p-multiselect-empty-message {
  //   color: var(--ta-text-color);
  //   padding: .5em;
  // }
}


// GRID COLUMN SELECTOR -- MULTISELECT
one-grid-column-selector p-multiselect{ display: inline-block;}
.p-multiselect {
  &.grid-options{
    display: block;
    width: 100%;
  border: 0 !important;
  margin-left: 0 !important;
  min-width: 30px !important;
  width: 30px !important;
  vertical-align: middle;
  background: none;

    .p-multiselect-trigger{
      color: #fff;
      background: none;
    }

    .p-multiselect-panel {
      left: auto !important;
      right: 0 !important;
      padding: 2px 0 0 !important;
      width: 220px !important;

      .p-multiselect-header {
        display: flex;
        flex-direction: column;

        .p-multiselect-close {
          display: none;
        }
      }

      .p-multiselect-items-wrapper {
        overflow-x: hidden;
      }
    }

    .p-multiselect-header {
      background-color: white !important;
      border-bottom: 1px solid var(--ta-panel-highlighted-border-color) !important;

      // p-header {
      //   color: var(--ta-text-color) !important;
      //   font-size: 13px !important;
      // }

      .p-multiselect-filter-container {
        background-color: var(--ta-dropdown-panel-header-bg);
        display: block;
        margin: 10px 0 4px 0;
        width: 100% !important;
      }

      .p-checkbox {
        display: none !important;
      }

      p-header {
        color: var(--ta-text-color);

        .p-checkbox {
          display: inline-block !important;
        }

        .p-checkbox-label {
          margin-left: 0px;
        }
      }

    }
  }
} //.p-multiselect.grid-options

// ACES Linkages > Attributes dropdowns values color indicators
// Moved from generic-selecor.scss because the style was not being applied after adding appendTo="body" to the dropdowns and multiselect
.p-dropdown-item-content {
  display: flex;
  .single { left: -12px; }
  .match-type { position: relative; width: 5px; margin-right: 5px; }
  .disabled { opacity: 0.5; }  
  .invalid { background-color: var(--ta-danger-color); }  
  .partial { background-color: var(--ta-warning-color); }  
  .full    { background-color: var(--ta-success-color); }
}