/* You can add global styles to this file, and also import other style files */

@import 'font-awesome/css/font-awesome.css';
@import 'bootstrap/dist/css/bootstrap.css';

@import 'ta-bootstrap4/dist/css/ta-bootstrap.css';
@import 'ta-bootstrap4/dist/css/ta-bootstrap-grid.css';
@import 'ta-bootstrap4/dist/css/ta-bootstrap-reboot.css';

@import 'primeicons/primeicons.css';
//@import 'primeng/resources/themes/nova/theme.css';
@import 'primeng/resources/primeng.min.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';

@import './scss/app.scss';

.b {
  border: solid 1px var(--ta-light-gray-color);
}
.b-top {  
  border-top: solid 1px var(--ta-light-gray-color);
}
.b-bottom {
  border-bottom: solid 1px var(--ta-light-gray-color);
}
.b-left {
  border-left: solid 1px var(--ta-light-gray-color);
}
.b-right {
  border-right: solid 1px var(--ta-light-gray-color);
}

.p-message { width: 100%; }
