/* SAFARI ?? */
::-webkit-scrollbar { 
  width:  var(--ta-scrollbar-size); //vertical
  height: var(--ta-scrollbar-size);//horizontal
}



/* GENERAL // VISIBLE ON HOVER PARENT CONTAINER */
* {
  scrollbar-width: thin; 
                        scrollbar-color: var(--ta-scrollbar-track-color)          var(--ta-scrollbar-track-color);
  &:hover, div:hover  { scrollbar-color: var(--ta-scrollbar-button-hover-color)   var(--ta-scrollbar-track-color); }
  &:active            { scrollbar-color: var(--ta-scrollbar-button-active-color)  var(--ta-scrollbar-track-color); }
}

/* AGGRID / always visible */
.ag-theme-balham * {
  scrollbar-color: var(--ta-scrollbar-button-color) var(--ta-grid-scrollbar-track-color); 
  &:hover { scrollbar-color: var(--ta-scrollbar-button-hover-color) var(--ta-grid-scrollbar-track-color); }
  &:active{ scrollbar-color: var(--ta-scrollbar-button-active-color) var(--ta-grid-scrollbar-track-color); }
}

/* SIDEBAR */ 
.scrollable,
#sidebar-right-bar [content-type="sidebar-right"] { 
  height: 100%; 
  overflow-y: auto; // without this the next scrollbar properties are not applied/rendered  
}

/* FIREFOX */
@-moz-document url-prefix() {
  *{scrollbar-width: initial;} // regular is thin enough
}

