.p-splitbutton {
  height: 27px;
  
  .p-splitbutton-defaultbutton {
    padding: 0 5px;
    background-color: var(--ta-primary-color);
    border: 1px solid transparent;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    color: var(--ta-white-color);
    outline: none;
  }

  .p-splitbutton-menubutton {
    max-width: 25px;
    border: 1px solid transparent;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: var(--ta-white-color);
    outline: none;    
    background-color: var(--ta-primary-color);
  }

  .p-menu {
    background: var(--ta-white-color);
    min-height: 15px;
    min-width: 100%;
    padding: 0;
    width: max-content;
    
    /* shadow */
    border: 0;
    box-shadow: 0px 3px 5px #0000004D;
    margin-top: 1px;
    
    ul.p-menu-list {
      a.p-menuitem-link {
        outline: none;
        padding: 0.5rem 0.75rem;
        color: var(--ta-text-color);
        .p-menuitem-icon {
          margin-right: 0.5rem;
        }

        &:hover {
          background-color: var(--ta-dropdown-panel-item-hover);
        }
      }
    }
  }
}