/* criteria.component */

.p-menu {
  &.p-shadow {
    border: 0 !important;
    box-shadow: 0px 3px 5px #0000004D !important;
    margin-top: 1px !important;
  }

  &.menu-items {  
    background-color: var(--ta-primary-color);
    padding: 0;
    width: 25px;

    &.article {
      margin-left: 9px;
    }

    &.linkage {
      margin-left: 17px;
    }

    &.importcriteria {
      margin-left: 17px;
    }

    .p-menuitem {
      .p-menuitem-link {
        margin: 4px 0px 4px 2px;
        outline: none;
        padding: 0;

        &:hover {
          background-color: var(--ta-primary-color);
        }

        .p-menuitem-icon, .p-menuitem-text {
          color: #fff !important;
        }
      }
    }

    .p-menuitem.add, .p-menuitem.delete {
      .p-menuitem-link {       
        .p-menuitem-text:before {
          font-family: "ta-icons";
          font-style: normal;
          font-weight: normal;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: .2em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: .3em;
          /* font-size: 120%; */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        }
      }
    }

    .p-menuitem.add {
      .p-menuitem-link {       
        .p-menuitem-text:before {
          content: '\e113';
        }
      }
    }

    .p-menuitem.delete {
      .p-menuitem-link {       
        .p-menuitem-text:before {
          content: '\e079';
        }
      }
    }
  }
}