@mixin p-checkbox-disabled {
  // background: #e9ecef;
  // border: 1px solid #ced4da;
  // color: #495057;
  background-color: $form-elem-disabled-bgcolor;
  opacity: $form-elem-disabled-opacity;
}


.p-checkbox {
  &.p-checkbox-focused {
   .p-checkbox-box {
      border: 0;
      border-color: rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 1px var(--ta-primary-color), 0 0 5px var(--ta-primary-color);
    }  
  }

  .p-checkbox-box {
    height: $checkbox-height;
    width: $checkbox-height;
    margin-left: 1px;
    text-align: center;
    color: var(--ta-white-color);
    border: 1px solid #a6a6a6;
    background-color: var(--ta-white-color);
   

    .p-checkbox-icon {
      padding: 2px;
      font-size: 10px;
    }

    &.p-highlight {
      background-color: var(--ta-primary-color);
      border-color: var(--ta-primary-border-color);
      &.p-disabled {
        @include p-checkbox-disabled;
      } 
    }

    &.p-disabled {
      @include p-checkbox-disabled;
    } 
  }

  &.p-checkbox-checked {
    .p-checkbox-box {
        &.p-highlight {
        background-color: var(--ta-primary-color) !important;
        border-color: var(--ta-primary-border-color);
        &.p-disabled {
          @include p-checkbox-disabled;
        }
      }
    }
  }

  &.p-checkbox-disabled {
    .p-checkbox-box {
      &.p-disabled {
        @include p-checkbox-disabled;
      }
    }
  }
}

.p-checkbox-label {
  margin: 0 0 0 0.5em;
  &.p-disabled{
    background: none !important;
  }
}
