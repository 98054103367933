

// DON'T ADD SASS VARIABLES TO THIS FILE. USE _variables2.scss INSTEAD
// DON'T ADD SASS VARIABLES TO THIS FILE. USE _variables2.scss INSTEAD
// DON'T ADD SASS VARIABLES TO THIS FILE. USE _variables2.scss INSTEAD

:root {
  // text default  
  --ta-text-color:           #272838;
  --ta-text-hover-color:     #0052cF;
  --ta-text-disabled-color:  #ccc;  

  // primary
  --ta-primary-color:             #0052cF;
  --ta-primary-border-color:      #0052cF;
  --ta-primary-disabled-color:    #6293de;
  --ta-primary-hover-color:       #002b9d;
  --ta-primary-text-color:        #fff;
  --ta-primary-text-hover-color:  #d2e4ff;

  // secondary
  --ta-secondary-color:           #4c4b63;
  --ta-secondary-border-color:    #4c4b63;
  --ta-secondary-disabled-color:  #908f9d;
  --ta-secondary-hover-color:     #232339;
  --ta-secondary-text-color:      #fff;
  --ta-secondary-text-hover-color:#fff;

  // info
  --ta-info-color:            #FF562B;
  --ta-info-border-color:     #FF562B;
  --ta-info-disabled-color:   #fb967c;
  --ta-info-hover-color:      #c41a00;
  --ta-info-text-color:       #fff;
  --ta-info-text-hover-color: #fff;

  // error
  --ta-danger-color:            #DC3545;
  --ta-danger-border-color:     #dc3545;
  --ta-danger-disabled-color:   #E67A85;
  --ta-danger-hover-color:      #C82333;
  --ta-danger-text-color:       #fff;
  --ta-danger-text-hover-color: #fff;

  // warning
  --ta-warning-color:           #FFC107;
  --ta-warning-border-color:    #ffc107;
  --ta-warning-disabled-color:  #FDD55D;
  --ta-warning-hover-color:     #E0A800;
  --ta-warning-text-color:        var(--ta-dark-color);
  --ta-warning-text-hover-color:  var(--ta-dark-color);

  // success
  --ta-success-color:           #28a745;
  --ta-success-border-color:    #28a745;
  --ta-success-disabled-color:  #72C485;
  --ta-success-hover-color:     #218838;
  --ta-success-text-color:      #fff;
  --ta-success-text-hover-color:#fff;


  // neutral
  --ta-white-color:       #fff;
  --ta-light-color:       #f8f9fa;
  --ta-whitesmoke-color:  #f5f5f5;
  --ta-light-gray-color:  #ededed;
  --ta-smooth-gray-color: #e6e6e7;
  --ta-silver-gray-color: #ccc;
  --ta-silver-dark-color: #bdbdbd;
  --ta-gray-color:        #868e96;
  --ta-dark-color:        #343a40;
  
  // fonts
  --ta-font-family: "Roboto", Arial, Helvetica, Sans-Serif;
  --ta-font-size: 13px;

  // footer
  --ta-footer-text-color:             #fff;
  --ta-footer-text-hover-color:       #94B1CE;
  --ta-footer-background-top-color:     var(--ta-primary-color);
  --ta-footer-background-bottom-color:  var(--ta-primary-color);

  // tabs
  --ta-tab-text-color:        var(--ta-text-color);
  --ta-tab-text-color-hover:  var(--ta-primary-color);
  --ta-tab-border-color:      var(--ta-panel-border-color);
  --ta-tab-background-color:  var(--ta-panel-header-background-color);

  --ta-tab-text-color-active:       var(--ta-primary-color);
  --ta-tab-border-color-active:     var(--ta-tab-border-color);
  --ta-tab-background-color-active: var(--ta-panel-body-background-color);

  --ta-tab-body-text-color:       var(--ta-text-color);
  --ta-tab-body-border-color:     var(--ta-tab-border-color);
  --ta-tab-body-background-color: var(--ta-tab-background-color-active);
  // --ta-tabview-border-hover-color: #E9ECEF;
  // --ta-tabview-disabled-color: #6C757D;

  // used for panels, cards, ...
  --ta-panel-body-padding:            1.25rem;
  --ta-panel-border-color:          #E6E6E7;  
  --ta-panel-body-background-color: #fff;

  --ta-panel-header-background-color:           #ECEEEF;
  --ta-panel-header-disabled-background-color:  #ECEEEF80;
  --ta-panel-header-border-color:               #E6E6E7;
  --ta-panel-header-disabled-color:             #ECEEEF;
  --ta-panel-header-hover-color:                #ECEEEF;
  --ta-panel-header-text-color:                   var(--ta-text-color);
  --ta-panel-header-text-hover-color:             var(--ta-text-color);
  --ta-panel-header-padding:                      0.5rem 1.2rem;
  --ta-panel-header-font-size:                    1.23rem;

  --ta-panel-highlighted-color:                 #F8F9FA;
  --ta-panel-highlighted-border-color:          #DFDFE0;
  --ta-panel-highlighted-for-home-color:        #e6eef2;
  --ta-panel-highlighted-border-for-home-color: #E6E6E7;

  // table zebra
  --ta-table-row-odd-color: #f2f2f2;

  // forms
  --ta-input-border-color:        #bdbdbd;
  --ta-input-disabled-color:      #eee;
  --ta-placeholder-color:         #757575;
  --ta-form-elem-disabled-bgcolor:#e9ecef;
  --ta-form-elem-disabled-opacity: .5;

  // Accordions
  --ta-acc-border-color:            var(--ta-panel-border-color);  
  --ta-acc-body-background-color:   var(--ta-panel-body-background-color);
  --ta-acc-body-padding:            var(--ta-panel-body-padding);

  --ta-acc-tab-separator-height:              3px;

  --ta-acc-header-height:                     38px; // = $input-height
  --ta-acc-header-btn-height:                 36px; // = $input-height - borders
  --ta-acc-header-btn-border-color:         #00000010;
  --ta-acc-header-border-color:               var(--ta-panel-header-border-color);
  --ta-acc-header-background-color:           var(--ta-panel-header-background-color);
  --ta-acc-header-disabled-background-color:  var(--ta-panel-header-disabled-background-color);
  --ta-acc-header-disabled-color:             var(--ta-panel-header-disabled-color);
  --ta-acc-header-hover-color:                var(--ta-panel-header-hover-color);
  --ta-acc-header-text-color:                 var(--ta-panel-header-text-color);
  --ta-acc-header-text-hover-color:           var(--ta-panel-header-text-hover-color);
  --ta-acc-header-padding:                    0 0 0 1rem;
  --ta-acc-header-font-size:                  var(--ta-panel-header-font-size);
    // level 1
  --ta-acc-l1-header-height:                  25px; // = $input-xs-height
  --ta-acc-l1-header-btn-height:              23px; // = $input-xs-height - borders
  --ta-acc-l1-tab-separator-height:           1px;

  // ag-grid
  --ta-ag-grid-border-color:        #d9dcde;
  --ta-table-odd-row-color:         #eee;
  --ta-ag-grid-selected-row-color:  #e1edf3;
  --ta-ag-grid-row-highlight-color: #a2ddc5;

  // grids (panel) and dialogs
  --ta-grid-dialog-header-color:            var(--ta-primary-color);
  --ta-grid-dialog-header-text-color:       var(--ta-primary-text-color);
  --ta-grid-dialog-header-text-hover-color: var(--ta-primary-text-hover-color);

  // Inline errors
  --ta-inline-error-background-color:   #E67A85;
  --ta-inline-error-text-color:           var(--ta-text-color);  
  
  // Scrollbars
  --ta-scrollbar-size: 8px;

  --ta-scrollbar-button-color:        rgba(0, 0, 0, .2);
  --ta-scrollbar-button-hover-color:  rgba(0, 0, 0, .3);
  --ta-scrollbar-button-active-color: rgba(0, 0, 0, .4);

  --ta-grid-scrollbar-track-color:    #f5f7f7;
  --ta-scrollbar-track-color-2:       rgba(0, 0, 0, .15); 
  --ta-scrollbar-track-color: transparent;
  
  // dropdowns panel
  --ta-dropdown-panel-shadow:           0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --ta-dropdown-panel-header-bg:      #eceeef;
  --ta-dropdown-panel-item-hover:     #eceeef;
  --ta-dropdown-panel-item-active:      var(--ta-primary-color);
  --ta-dropdown-panel-item-text-active: var(--ta-primary-text-color);

  // Search omnibox
  --ta-omnibox-heihgt: 31px;
  
  // footer
  --ta-footer-height: 35px;
  
  // side menu
  --ta-side-menu-width:                 250px;
  --ta-side-menu-collapsed-width:       48px;
  --ta-side-menu-item-height:           38px;
  --ta-side-menu-collapsed-logo-height: 48px;
  --ta-side-menu-item-color:          #eceeef;
  
  // grid toolbar
  --ta-grid-bar-height: var(--ta-side-menu-item-height);
  
  // trace bar
  --ta-trace-bar-height: var(--ta-side-menu-item-height);
  
  // admin bar
  --ta-admin-bar-height: 74px;

}
