@mixin ui-toast-icon {
  position: absolute;
  display: inline-block;
  left: .2em;
  top: 15px;
  padding: 0;
  font-size: 24px !important;
  font-family: "ta-icons";
}


.p-toast {  
  width: 20em !important;
  z-index: 9999 !important;

  .hide {
    visibility:hidden;
    opacity:0;
    height: 0px;
    transition:visibility 0.2s linear, height 0.2s linear;
  }

  .p-toast-message {
    box-shadow: var(--ta-dropdown-panel-shadow);
    display: block;
    margin: 0 0 1em 0;
    margin-bottom: .75em;
    overflow: hidden;
    position: relative;

    .p-toast-message-content {
      min-height: 70px;
      padding: .5em 1em;

      .p-toast-message-icon {
        display: inline-block;
        font-size: 2em;
        padding: 0;
        position: absolute;
        top: .25em;
        left: .5em;
      }

      .p-toast-message-text {
        margin-left: 2.5em;
        padding: 0 0 .25em 0;

        .p-toast-summary {
          font-weight: 700;
          padding: 0 0 .5em 0;
          display: block;
        }

        .p-toast-deatil {
          margin: 10px 0 10px 10px;
        }
      }

      .p-toast-icon-close {
        display: inline-block;
        font-size: 11px;
        outline: none;
        position: absolute;
        top: .50em;
        right: .5em;
      }
    }    

    .p-toast-detail {
      margin: 10px 10px 10px 10px;
      max-width: 15em;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.p-toast-message-success {
      .p-toast-message-icon:before {
        @include ui-toast-icon;
        content: '\e107';
      }

      background-color: var(--ta-success-color)!important;
      color: var(--ta-success-text-color)!important;

      .p-toast-icon-close {
        color: var(--ta-success-text-color)!important;
      }
    }


    &.p-toast-message-error {
      .p-toast-message-icon:before {
        @include ui-toast-icon;
        content: '\e110';
      }

      background-color: var(--ta-danger-color)!important;
      color: var(--ta-danger-text-color)!important;

      .p-toast-icon-close {
        color: var(--ta-danger-text-color)!important;
      }
    }


    &.p-toast-message-info {
      .p-toast-message-icon:before {
        @include ui-toast-icon;
        content: '\e122';
      }

      background-color: var(--ta-info-color)!important;
      color: var(--ta-info-text-color)!important;

      .p-toast-icon-close {
        color: var(--ta-info-text-color)!important;
      }
    }


    &.p-toast-message-warn {
      .p-toast-message-icon:before {
        @include ui-toast-icon;
        content: '\e131';
      }

      background-color: var(--ta-warning-color)!important;
      color: var(--ta-warning-text-color)!important;

      .p-toast-icon-close {
        color: var(--ta-warning-text-color)!important;
      }
    }

    &.p-toast-announcement {
      color: #272838;
      background-color: #f1f1f1;
      border: 1px solid #bdbdbd;
      width: 262px;
      // height: 93px; // BSTI: commented after discussing with OFR because it breaks the announcement for planned maintenance and it looks better for the other ones as well
      font-size: 13px;

      .p-toast-message-content {
        height: 100%;
      }

      .p-toast-message-text {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .p-toast-detail {
        margin: 10px 0 4px 10px;
        flex: 1;
      }

      .p-toast-message-icon {
        display: inline-block;
        font-size: 2em;
        padding: 0;
        position: absolute;
        top: .5em;
        left: .5em;
        color: #ff5500!important;
      }

      .p-toast-action {
        text-align: right;
        cursor: pointer;
      }
    }

    &.p-toast-survey {
      background-color: var(--ta-primary-color);
      border: 1px solid var(--ta-white-color);
      color: var(--ta-white-color);

      .p-toast-icon-close-icon {
        color: var(--ta-white-color);
      }

      .p-toast-message-text{ margin-left: 0;}

      .p-toast-summary {
        font-size: 15px !important;
        font-weight: bold !important;
        padding: 8px 8px 0 8px !important;
        margin-left: 2.5em;
      }

      .p-toast-action {
        padding-left: 10px; 
        padding-right: 10px; 

        .previous-action {
          background-color: #FF562B;
          border-radius: 3px;
          cursor: pointer;
          height: 25px;
          margin: 8px 0;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .action {
          cursor: pointer;
          display: block;
          text-align: center;
          font-size: 0.9em;
        }
      }
    }
  }
}
