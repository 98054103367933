@import "../variables2";

.p-dropdown {
  min-height: $dropdown-xs-height;
  background-color: var(--ta-white-color);
}

.p-dropdown-label {
  display: flex !important;
  align-items: center;
  line-height: $dropdown-xs-height - 2;
  padding: $dropdown-xs-padding;
  font-size: $dropdown-xs-font;
  width: 0 !important;
}

.p-dropdown-item-content{
  height: 100%;
  .content{ align-self: center;}
}

.dropdown-xxs {
  height: $dropdown-xxs-height;
  min-height: $dropdown-xxs-height;
  &.p-dropdown-label,
  .p-dropdown-label {
    line-height: $dropdown-xxs-height - 1;
    padding: $dropdown-xxs-padding;
    font-size: $dropdown-xxs-font;
  }
}

.dropdown-xs { 
  height: $dropdown-xs-height; 
  &.p-dropdown-label, .p-dropdown-label {
    //take style from default
  }
  &.p-dropdown-panel, .p-dropdown-panel {
    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        .p-dropdown-item {
          // padding: 0.2em 0.857em;
        }
      }
    }
  }
}
.dropdown-s { 
  height: $dropdown-s-height; 
  &.p-dropdown-label, .p-dropdown-label { 
    line-height: $dropdown-s-height - 2;
    padding: $dropdown-s-padding;
    font-size: $dropdown-s-font;
  }  
  &.p-dropdown-panel, .p-dropdown-panel {
    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        .p-dropdown-item {
          // padding: 0.429em 0.857em;
        }
      }
    }
  }
}
.dropdown { 
  height: $dropdown-height; 
  &.p-dropdown-label, .p-dropdown-label { 
    line-height: $dropdown-height - 2;
    padding: $dropdown-padding;
    font-size: $dropdown-font;
  }
}
.dropdown-l { 
  height: $dropdown-l-height; 
  &.p-dropdown-label, .p-dropdown-label { 
    line-height: $dropdown-l-height - 2;
    padding: $dropdown-l-padding;
    font-size: $dropdown-l-font;
  }
}

.p-dropdown-clear-icon {
  position: relative !important;
  width: 9px;
  margin-right: 2px;
}

.p-dropdown-trigger {
  width: 2em;
  color: $dropdown-trigger-color;
  background-color: var(--ta-light-color);
}

.p-dropdown-panel {
  width: auto;
  height: auto;
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: var(--ta-white-color);
  box-shadow: var(--ta-dropdown-panel-shadow);
  margin-top: -2px;

  .p-dropdown-header {
    color: #333333;
    background-color: var(--ta-dropdown-panel-header-bg);
    border-bottom: 1px solid #eaeaea;
    margin: 0;
    padding: 0.429em 0.857em 0.429em 0.857em;

    .p-dropdown-filter {
      padding-right: 2em;
    } 

    .p-dropdown-filter-icon {
      color: #007ad9;
      right: .5em;
    }
  }
  .p-dropdown-items-wrapper{
    max-height: $dropdown-max-height;

    ul.p-dropdown-items{
      margin: 0;
      li.p-dropdown-item {
        display: flex;
        align-items: center;
        min-height: 25px;
        padding: 3px 1rem;
        color: var(--ta-text-color);
        background-color: #0000;

        &:hover {background-color: var(--ta-dropdown-panel-item-hover);}

        &.p-highlight {
          background-color: var(--ta-dropdown-panel-item-active) !important;
          color: var(--ta-dropdown-panel-item-text-active)!important;
        }

        span {
          display: inline-block;
          max-width: $dropdown-item-max-width;
          padding-right: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; 
        }
      }
    }
  }
}

// VIRTUAL SCROLL
.p-scroller{
  contain: none !important;
  height: 45vh!important;
  ul.p-scroller-content{
    position: relative;
    width: auto;
  }
}
// STICK PANEL TO THE RIGHT OF THE SELECTBOX 
// 1. appendTo=body + DIRECTIVE  oneRightAlign  src\app\private\directives\dropdown-right-align.directive.ts  // Used in right sidebar to avoid panel hidden under column
.p-dropdown-one-right-align-directive { left: auto !important; }
// 2. class [styleClass]="'multiselect-xs one-dropdown-align-right'"
.one-dropdown-align-right .p-overlay{ left: auto !important; right: 0;}
