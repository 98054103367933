.p-accordion {  // level 0

  &.mb-3{ margin-bottom: 0 !important;} // removes separator in article accordions
  .tab-separator { display: none; } // removes separator in sidebars
  .p-accordion-tab {
    margin-bottom: var(--ta-acc-tab-separator-height); // SEPARATION BETWEEN TABS

    .p-accordion-header {
      height: var(--ta-acc-header-height);
      color: var(--ta-acc-header-text-color);
      font-size: var(--ta-acc-header-font-size);
      border: 1px solid var(--ta-acc-header-border-color);
      border-left-width: 2px;
      border-radius: 0;
      background-color: var(--ta-acc-header-background-color);
      transition: background .3s;

      &:hover{ 
        background-color: var(--ta-acc-header-border-color);
        .p-accordion-toggle-icon{ color: var(--ta-primary-color);}
      }

      .p-accordion-header-link { }

      .p-accordion-toggle-icon {
        order: 2;
        display: flex; align-items: center; justify-content: center;
        width: var(--ta-acc-header-height); 
        height: 100%;
        border-left: 1px solid var(--ta-acc-header-btn-border-color);
        transition: .3s;
        &:before{ position: relative; top: -1px; }
      }

      a {
        height: 100%;
        outline: none;
        padding: var(--ta-acc-header-padding);
        a {
          padding: 0;
        }
      }

      p-header {
        width: 100%;

        // header buttons
        .acc-header-btn{
          display: flex; align-items: center; justify-content: center;
          width: $input-s-height;
          height: var(--ta-acc-header-btn-height);
          font-size: 15px;
          color: var(--ta-text-color);
          border: none;
          border-left: 1px solid var(--ta-acc-header-btn-border-color);
          background: none;
          cursor: pointer;
          transition: .3s;
          
          &:hover{
            color: var(--ta-primary-text-color);
            background-color: var(--ta-primary-color);
          }
          
          &.disabled {
            background-color: transparent;
            pointer-events: none;
            opacity: 0.4;
            cursor: default !important;
          }      
          i { cursor: pointer !important;            
            &.disabled { cursor: default !important; }
          }
        } // .acc-header-btn
        a.clear-fields{ 
          width: auto;
          padding: 0 5px;
          font-size: 13px; 
        }
        // END header buttons

        .any-field-value-set-tag { position: relative; top: -1px; font-size: 12px; margin-left: 3px; }  // +.ta-icon-circle > circle beside title in edited sections 
        .filter-in-use { margin-left: 3px; color: var(--ta-info-color); } // +.ta-icon-filtering > sidebar filter
      } // p-header

      &.p-disabled {
        pointer-events: none !important;
        opacity: inherit;
        cursor: default !important;
        background-color: var(--ta-acc-header-disabled-background-color) !important;
        
        > a.p-accordion-header-link {
          .p-accordion-toggle-icon, i, span, a, input[type=button] {
            opacity: $form-elem-disabled-opacity;
          }
          .force-enabled {
            opacity: inherit;
            cursor: pointer !important;
            pointer-events: visible !important;
            // color: initial !important;

            .p-accordion-toggle-icon, i, span, a, input[type=button] {
              opacity: inherit;
            }
          }
        }
      }
      * {align-items: center; transition: none;}
    } // .p-accordion-header

    .p-accordion-content {
      color: var(--ta-text-color);
      background-color: var(--ta-acc-body-background-color);
      border: 1px solid var(--ta-acc-header-border-color);          
      border-top: none;
      padding: 15px;      
    }

    // ACTIVE TAB
    .p-highlight{
      border-left-color: var(--ta-primary-color);
      &>.p-accordion-header-link{
        p-header{ color: var(--ta-primary-color);}

      }
    }
   
  } //.p-accordion-tab

  // LEVEL 1 (sub accordion)
  .p-accordion {  // level 1
    margin-top: var(--ta-acc-l1-tab-separator-height);

    .p-accordion-tab {    
      margin-bottom: var(--ta-acc-l1-tab-separator-height) !important;
      .p-accordion-header {
        height: var(--ta-acc-l1-header-height);
        border-left-width: 1px;
        border-right-width: 0;

        // .p-accordion-toggle-icon{width: calc( var(--ta-acc-header-height) - 1);}
        
        p-header{
          font-size: 13px;

          .acc-header-btn{ height: var(--ta-acc-l1-header-btn-height);}
        }
      }
      .p-accordion-content{border: none;}
    }
  } //.p-accordion  level 1

} //.p-accordion  level 0
