.p-panel {


  .p-panel-header {
    padding: var(--ta-panel-header-padding);
    color: var(--ta-grid-dialog-header-text-color);
    border: none;
    background: var(--ta-grid-dialog-header-color);
    // border-bottom-width: 1px;
    // border-bottom-style: solid;
    // border-bottom-color: var(--ta-panel-header-border-color);

    p-header {
      width: 100%;
    }

    .btn-group{
      button{ background: none; border: none;}
    }

    .grid-no-data-msg{
      background: var(--ta-light-gray-color);
      color: var(--ta-text-color);
      padding: 0 10px 0 5px;
      border-radius: 3px;
    }
  }


  .p-panel-content {
    background: var(--ta-panel-body-background-color);
    border: 1px solid var(--ta-panel-header-border-color);
    color: var(--ta-text-color);
    padding: var(--ta-panel-body-padding);
  }


  &.p-panel-padding-0 {
    .p-panel-content {
      padding: 0;
    }
  }

  // PANEL AG-GRID //
  &.ta-one-panel {

    .p-panel-header{
      border: none;
      height: 38px;
      div {min-height: 100%;align-items: center;}
      span.title { font-size: var(--ta-panel-header-font-size); font-weight: 500; letter-spacing: 0.2px; line-height: 19px; }
      a.next, a.prev { 
        color: var(--ta-grid-dialog-header-text-color); 

        &[style*="visibility: hidden"] {visibility: visible !important; opacity: 0.4; cursor: default;}
      }
    }   
    
    .p-panel-content{
      border-top: none;
      .ag-theme-balham .ag-root-wrapper{ border: none;}
    }

    // .p-multiselect {
    //   background: transparent;
    //   .p-multiselect-trigger { background: transparent; color: white; }
    // }
  }
}