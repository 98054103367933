@import
  "accordion",
  "checkbox",
  "chips",
  "dialog",
  "drag&drop",
  "dropdown",
  "lightbox",
  "megamenu",
  "message",
  "multiselect",
  "panel",
  "sidebar",
  "splitbutton",
  "steps",
  "tabview",
  "toast",
  "tooltip";


.p-inputtext {
  font-weight: normal;
}

// Bug Fix
// https://github.com/primefaces/primeng/issues/10682
// https://github.com/primefaces/primeng/issues/10715
.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
