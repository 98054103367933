.p-tooltip {
  max-width: 40vw;  
  //text box
  .p-tooltip-text {
    padding: 0.5rem 0.7rem;
    line-height: 1.2;
    border-radius: 3px;
    color: var(--ta-white-color);
    background: var(--ta-dark-color);
  }
  //arrows
  &.p-tooltip-top   { .p-tooltip-arrow{ border-top-color:   var(--ta-dark-color); } }
  &.p-tooltip-right { .p-tooltip-arrow{ border-right-color: var(--ta-dark-color); } }
  &.p-tooltip-bottom{ .p-tooltip-arrow{ border-bottom-color:var(--ta-dark-color); } }
  &.p-tooltip-left  { .p-tooltip-arrow{ border-left-color:  var(--ta-dark-color); } }


  &.nowrap .p-tooltip-text { display: table ; white-space: nowrap; } // in use?
}
