table.flat {
  color: var(--ta-text-color);
  width: 100%;

  thead {
    background-color: var(--ta-panel-header-background-color);
    
    tr {
      th {
        font-size: 15px;
        font-weight: 700;
        padding: 10px 15px;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid var(--ta-silver-gray-color);

      &.highlight {
        background-color: #F8F9FA;
        font-weight: 700;

        td {
          
        }
      }

      td {
        padding: 10px 15px;

        i {
          color: var(--ta-primary-color);
          cursor: pointer;

          &.disabled {
            color: #AFB4B9;
            cursor: default;
          }
        }
      }
    }

    input {
      width: 100%;
    }
  }
}