// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #003366 !default;
$black:  #000 !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$blue:    #3F9DD1 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #87C7AD !default;
$ta-one:  #003366 !default;
$ta-two:  #5C89B6 !default;
$ta-three:#8FAEC9 !default;
$ta-four: #E75112 !default;
$ta-five: #6E1868 !default;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "ta-one": $ta-one,
  "ta-two": $ta-two,
  "ta-three": $ta-three,
  "ta-four": $ta-four,
  "ta-five": $ta-five
);

$theme-colors: (
  "primary": $blue,
  "secondary": $gray-600,
  "success": $green,
  "info": $cyan,
  "warning": $yellow,
  "danger": $red,
  "light": $gray-100,
  "dark": $gray-800,
  "ta-one": $ta-one,
  "ta-two": $ta-two,
  "ta-three": $ta-three,
  "ta-four": $ta-four,
  "ta-five": $ta-five
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$ta-font-path: '../fonts' !default;
$ta-css-icon-prefix: "ta-icon";
$ta-li-width: (30em / 14);
$ta-border-color:     #eee;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;