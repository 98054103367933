@import "../variables";

.ag-theme-balham {
  font-family: inherit;
  color: inherit;

  &.ag-overflow-x-hidden {
    .ag-body-viewport {
      overflow-x: hidden !important;
    }

    // .ag-body-horizontal-scroll {
    //   display: none!important;
    // }
  }

  &.ag-overflow-y-hidden {
    .ag-body-viewport {
      overflow-y: hidden !important;
    }
  }

  &.ag-rows-hidden {
    .ag-body-viewport {
      div[role="row"] {
        visibility: hidden !important;
      }
    }
  }

  .ag-body-viewport {
    .ag-center-cols-viewport {
      // overflow-x: hidden;
      .ag-center-cols-container { min-width: 100%; } // hack to avoid blank space on the right when width of all columns is less than grid viewport width
    }

    .ag-cell {
      // &.ag-cell-focus {
      //   border: none !important;
      //   outline: 0 !important;
      // }

      &.ellipsis {
        min-width: 0;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.main-cell {
        color: var(--ta-primary-color) !important;
        font-weight: 500;
        cursor: pointer;

        &:focus, &:hover {
          color: var(--ta-primary-hover-color) !important;
          text-decoration: underline;
        }
      }

      a {
        border-radius: 3px;
        [class*=ta-icon-]{  //buttons within grids
          padding: 3px;
          border-radius: 3px;
          transition: .3s;
          &:hover{
            color: var(--ta-white-color);
            background-color: var(--ta-primary-color);
          }
        }
      }
    }

  .cursor-pointer { cursor: pointer; }

//#region MARK: ROWS BG COLOR
    .ag-row {
      border-color: var(--ag-row-border-color) !important;
      &.ag-row-hover { background-color: var(--ta-light-gray-color) !important; }

      &.ag-row-selected {
        border-color: var(--ta-ag-grid-border-color);
        background-color: var(--ta-ag-grid-selected-row-color) !important;
        background-image: none;

        &::before {
          border-color: var(--ta-ag-grid-border-color);
          background-color: var(--ta-ag-grid-selected-row-color) !important;  
          background-image: none;
        }
      }

      &.ag-row-group  {background-color: var(--ta-panel-header-background-color) !important;
        &.ag-row-hover:before{background-color: var(--ta-panel-border-color) !important;}
        &.ag-row-selected { background-color: var(--ta-panel-border-color) !important; border-color: #b1babf;
          &:before{ background-color: var(--ta-panel-border-color) !important; }
          &.ag-row-hover{background-color: var(--ta-panel-border-color) !important;}
        }  
      }      

      &.ag-row-loading { display: none; }
    
      &.one-row-issue:not(.ag-row-selected) {
        background-color: #FCEDBF !important;
        background-image: none;
        &::before {
          background-color: #FCEDBF !important;
          background-image: none;
        }

        &.ag-row-hover { 
          background-color: #ffe69c !important;
          background-image: none;
          &::before {
            background-color: #ffe69c !important;
            background-image: none;
          }
        }
      }
      
      &.one-row-highlight:not(.ag-row-selected)  {
        background-color: var(--ta-ag-grid-row-highlight-color) !important;
        background-image: none;
        &::before {
          background-color: var(--ta-ag-grid-row-highlight-color) !important;
          background-image: none;
        }

        &.ag-row-hover {
          background-color: var(--ta-ag-grid-row-highlight-color) !important;
          background-image: none;
          &::before {
            background-color: var(--ta-ag-grid-row-highlight-color) !important;
            background-image: none;
          }  
        }
      }

    } //.ag-row
  } //.ag-body-viewport
  
  .ag-cell-focus.ag-cell-inline-editing,
  .ag-popup-editor {
    background-color: transparent!important;
  }


  .ag-row-even {
    background-color: var(--ta-light-color);
  }
//#endregion

//#region MARK: PAGINATOR (FOOTER)
  .ag-paging-panel {
    color: var(--ta-text-color);
    border-color: var(--ta-panel-header-border-color);

    .ag-picker-field { display: none; }

    .ag-paging-row-summary-panel {
      color: var(--ta-text-color);
      span{padding: 0 3px 0 0;}
    }
    .ag-paging-page-summary-panel {
      color: var(--ta-text-color);

      .ag-paging-button{   
        color: var(--ta-one-color);
        border-radius: 3px;
        transition: background-color .3s;     

        &.ag-disabled { opacity: 0.4; pointer-events: none;} 

        &:hover:not(.ag-disabled),
        &:hover:not([disabled]){ background-color: var(--ta-light-gray-color); }
      }      
    }
  }
//#endregion

//#region MARK: FILTERS
  // grid header
  .ag-floating-filter-input [disabled]{
    opacity: 0.8 !important;

    .ag-input-field-input-filtered { box-shadow: 0 0 0 2px var(--ta-info-color) !important; }
  }

  // dropdown
  .ag-set-filter-list {
    height: auto;
    min-height: calc(var(--ag-list-item-height)* 6);
    max-height: calc(100vh - 400px) !important;
    overflow: hidden scroll;
  }


  .ag-menu {
    // box-shadow: none!important;
    background: var(--ta-panel-body-background-color) !important;
    border-color: var(--ta-panel-header-border-color) !important;

    .ag-filter {
      .ag-filter-header-container {
        height: auto !important;
        min-height: 24px;

        label {
          margin: 6px;
          display: flex;
          align-items: center;

          span.ag-filter-value {
            height: auto!important;
            flex-grow: 1;
          }
        }
      }

      .ag-filter-apply-panel {
        button#clearButton,
        button#applyButton {
          background-color: var(--ta-secondary-color);
          color: var(--ta-secondary-color-text-color);
          border: solid 1px var(--ta-secondary-border-color);
        }
      }

      .ag-set-filter-list {
        padding: 6px;

        .ag-virtual-list-viewport{
          .ag-virtual-list-container{
            .ag-virtual-list-item {
              label.ag-set-filter-item {
                display: flex;
                align-items: center;

                span.ag-filter-value {
                  height: auto!important;
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }

      .p-datepicker {
        border-left: none;
        border-right: none;
        border-bottom: none;
      }

      input[type="checkbox"].ag-filter-checkbox {
        display: inline-block;
        width: auto;
        height: auto;
        appearance: auto;
        -moz-appearance: checkbox;
        -webkit-appearance: checkbox;
      }

      input.ag-filter-filter {
        width: auto!important;
        margin: 4px !important;
      }

      label {
        color: var(--ta-text-color);
      }

      .ag-filter-apply-panel {
        gap: 4px;
      }

      .ag-button {
        background-color: var(--ta-primary-color);
        border-radius: 3px;
        border: 0;
        color: var(--ta-secondary-text-color);
        margin-left: 0;
        width: 100%;
      }
    }
  }

  input{ min-height: auto; }  
  .ag-date-filter input[type=date] { display: inline-block; }
  .ag-header-row .ag-header-cell .ag-header-cell-resize { right: 0px }
  .ag-header-row .ag-header-cell::after  { right: 4px }
  .ag-input-field-input-filtered {
    color: var(--ta-info-text-color) !important;
    background: var(--ta-info-color) !important;
    border: 0 !important;
    box-shadow: 0 0 0 4px var(--ta-info-color) !important;
  }

  .ag-menu.ag-filter-menu{
    .ag-picker-field-wrapper { // input (div) in dropdown (contains, equal, ...)
      border-radius: 0;
      border-color: var(--ta-input-border-color) !important;
      height: 21px;
      min-height: auto !important;
    }
    .ag-text-field-input { // filter input
      height: 21px;
      padding-top: 1px;
    }
    .ag-input-field-input-filtered {box-shadow: none !important;}
    .ag-radio-button-input-wrapper.ag-checked::after {color: var(--ta-primary-color);}
  }
//#endregion

//#region MARK: ICONS

  // filter icon
  .ag-icon-filter:hover { color: var(--ta-info-color); }
  .ag-filter-active .ag-icon-filter { 
    border-radius: 3px;;
    color: var(--ta-info-text-color);
    background-color: var(--ta-info-color); 
    &:before{
      font-family: 'ta-icons';
      content: "\e237";
      font-size: 12px;
      position: relative; top: -4px;
    }
  } 

  // drag & drop
  .ag-icon-grip:before {
    position: relative; top: -4px;
    font-family: 'ta-icons'; 
    content: '\e336'; 
    color: var(--ta-gray-color); 
  }

  // column sorting 
  .ag-header-cell-sorted-none .ag-sort-none-icon{display: block !important; position: absolute; top:-1000000px; right:-1000000px; opacity: 0; transition: opacity .2s;}
  .ag-header-active .ag-header-cell-sorted-none .ag-sort-none-icon{ position: relative; top: auto; right: auto; opacity: 1; }

  .ag-icon.ag-icon-asc,
  .ag-icon.ag-icon-desc{
    margin-top: -1px;
    padding: 0 1px;
    font-size: 13px;
    color: var(--ta-info-color);
    &:before{ font-family: 'FontAwesome'; }
  }
  .ag-icon.ag-icon-asc { &::before{content: "\f160";} }
  .ag-icon.ag-icon-desc{ &::before{content: "\f161";} }

  // group chevron
  .ag-icon-tree-open::before,
  .ag-icon-tree-closed::before {
    font-family: 'ta-icons';     
    position: relative; top: -6px;
    font-size: 12px;
  }
  .ag-icon-tree-open::before {content: '\e146';}
  .ag-icon-tree-closed::before {content: '\e147';}

  // checkbox
  .ag-checkbox-input-wrapper {
    top: 0px;
    font-family: 'FontAwesome';
    border: 1px solid var(--ta-input-border-color);
    border-radius: 0;
    &::after {content: '';}

    &.ag-checked{
      border: none;
      background: var(--ta-primary-color);
      &::after{
        content:'\f00c'; 
        top: 1px; left: 3px;
        font-size: 11px;
        color: var(--ta-white-color);        
      }
    }
    &.ag-indeterminate{ // checkbox
      background: var(--ta-text-disabled-color);
      &::after{
        content: "_";
        top: -10px; left: 3px;
        font-size: 17px;
        color: var(--ta-grey-color);        
      }
    }
  }
//#endregion

//#region MARK: EDITABLE CELLS
  .ag-cell-error { padding: 0;
    .ag-cell-error-container { height: 100%; width: 100%;
      &.error { background-color: pink; }
      span { padding: 0 11px; }
    }
  }
//#endregion
 
}
