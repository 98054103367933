.p-sidebar {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  color: #333333;
  padding: 0.571em 1em;

  &.ui-sidebar-md  {
    width: 32em !important;
  }

  .p-sidebar-header {
    margin-bottom: 8px;
  }

  .p-sidebar-content {
    height: 100%;
    overflow-y: visible;
  }
}

.p-sidebar-mask {
  background-color: rgba(0, 0, 0, 0.4) !important;
}