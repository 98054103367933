%confirm-title-base {
  font-family: "ta-icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.confirm-delete {
  .p-dialog-title::before {
    content: '\e079';
    @extend %confirm-title-base
  }
}

.confirm-new {
  .p-dialog-title::before {
    content: '\e087';
    @extend %confirm-title-base
  }  
}

.confirm-oe {
  .p-dialog-title::before {
    content: '\e233';
    @extend %confirm-title-base
  }  
}

.confirm-copy {
  .p-dialog-title::before {
    content: "\f0c5";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    margin-right: 1em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }  
}

.confirm-email {
  .p-dialog-title::before {
    content: "\e299";
    display: inline-block;
    font: normal normal normal 14px/1 ta-icons;
    font-size: inherit;
    margin-right: 1em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }  
}

.confirm-link {
  .p-dialog-title::before {
    content: '\e335';
    @extend %confirm-title-base
  }  
}