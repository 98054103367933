@import "../variables";

.ui-lightbox {
  max-width: 95vw ;
  max-height: 90vh ;
  left: 50% ;
  top: 50% ;
  transform: translate(-50%, -50%) ;

  .ui-lightbox-caption {
    display: block ;
    visibility: hidden ;
    position: absolute ;
    top: 0px ;
    right: 0px ;

    a.ui-lightbox-close {
      background: var(--ta-white-color) ;
      position: absolute ;
      top: 3px ;
      right: 20px ;
      visibility: visible ;
      border-width: 0.16em ;
      border-style: solid ;
      border-color: var(--ta-light-gray-color);
      cursor: pointer;

      .pi-times {
        color: var(--ta-text-color) ;
        position: relative ;
        top: 2px ;
        font-size: 1.25em ;
      }
    }
  }

  .ui-lightbox-content {
    max-width: 90vw ;
    max-height: 90vh ;
    overflow: auto;
  }

  .ui-lightbox-nav-right, .ui-lightbox-nav-left {
    display: none ;
  }
}
