/*
  Variables

  1. global
  2. input
  3. checkbox
  4. dropdown
  5. multiselect
  6. button
  7. fixed height boxes

*/

/* 1. global */
$titles-dropdown-width: 200px; // dropdowns besite the title in settings, control panel ...
$titles-extra-dropdown-width: 180px;


/* 2. input */
$input-xxs-font:12px;
$input-xs-font: 13px;
$input-s-font:  14px;
$input-font:    16px;
$input-l-font:  20px;

$input-xxs-height:21px;
$input-xs-height: 25px;
$input-s-height:  31px;
$input-height:    38px;
$input-l-height:  48px;

$input-xxs-padding: 0 2px 0 5px;
$input-xs-padding: 0 2px 0 5px;
$input-s-padding:  0 2px 0 5px;
$input-padding:    0 2px 0 10px;
$input-l-padding:  0 2px 0 10px;


/* 3. checkbox + radio */
$checkbox-height: 18px;
$radio-height: $checkbox-height;


/* 4. dropdown */
$dropdown-xxs-font:    $input-xxs-font;
$dropdown-xs-font:    $input-xs-font;
$dropdown-s-font:     $input-s-font;
$dropdown-font:       $input-font;
$dropdown-l-font:     $input-l-font;

$dropdown-xxs-height:  $input-xxs-height;
$dropdown-xs-height:  $input-xs-height;
$dropdown-s-height:   $input-s-height;
$dropdown-height:     $input-height;
$dropdown-l-height:   $input-l-height;

$dropdown-xxs-padding: $input-xxs-padding;
$dropdown-xs-padding: $input-xs-padding;
$dropdown-s-padding:  $input-s-padding;
$dropdown-padding:    $input-padding; 
$dropdown-l-padding:  $input-l-padding; 

$dropdown-trigger-color: #727B84;

$dropdown-item-max-width: 46vw!important;
$dropdown-max-height: 46vh!important;
$titles-dropdown-max-height: 57vh !important; //  settings, reports, etc

$dropdown-panel-box-shadow: 0px 3px 6px #00000029;


/* 5. multiselect */
$multiselect-xxs-font:   $input-xxs-font;
$multiselect-xs-font:    $input-xs-font;
$multiselect-s-font:     $input-s-font;
$multiselect-font:       $input-font;
$multiselect-l-font:     $input-l-font;

$multiselect-xxs-height:$input-xxs-height; 
$multiselect-xs-height: $input-xs-height; 
$multiselect-s-height:  $input-s-height; 
$multiselect-height:    $input-height;
$multiselect-l-height:  $input-l-height; 

$multiselect-xxs-padding: 0 5px;
$multiselect-xs-padding: 0 5px;
$multiselect-s-padding: 0 5px;

$multiselect-dropdown-item-max-width:  $dropdown-item-max-width;
$multiselect-dropdown-max-height: $dropdown-max-height;

/* 6. button */
$button-ghost-border-color-grey: #d6dce5;

$button-border-radius: 3px;

$button-width: 100px;

$button-xs-height: $input-xs-height;
$button-s-height:  $input-s-height;
$button-height:    $input-height;
$button-l-height:  $input-l-height;

$button-xs-font: $input-xs-font;
$button-s-font:  $input-s-font;
$button-font:    $input-font;
$button-l-font:  $input-l-font;

$button-xs-padding: 0 10px;
$button-s-padding:  0 15px;
$button-padding:    0 18px;
$button-l-padding:  0 20px;
/**/
$buttongroup-xs-padding: 0 8px;
$buttongroup-s-padding:  0 12px;
$buttongroup-padding:    0 15px;
$buttongroup-l-padding:  0 18px;
/**/

/* 7. fixed height boxes (same heights as buttons, inputs, etc) */
$box-xs-height: $input-xs-height;
$box-s-height:  $input-s-height;
$box-height:    $input-height;
$box-l-height:  $input-l-height; 