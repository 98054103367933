.p-steps {

  .p-steps-item {
      cursor: pointer !important;

    .p-menuitem-link {
      align-items: flex-start;
      cursor: pointer !important;
      outline: none;

      .p-steps-number {
        background: var(--ta-primary-color);
        border-radius: 50%;
        color: var(--ta-white-color);
        font-size: 16px;
      }

      .p-steps-title {
        color: #333333;
        font-weight: 700;
      }

    }

    &.p-disabled {
      cursor: default !important;
    }


    &.p-steps-current {
      .p-menuitem-link {
        .p-steps-number {
          background: var(--ta-one-color);
        }
      }
    }

  }

}