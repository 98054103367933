@import "../variables";

$txt-clr: var(--ta-one-text-color);
$bg-clr: var(--ta-text-color);

.p-tooltip {
  max-width: 40vw;  
  //text box
  .p-tooltip-text {
    padding: 0.5rem 0.7rem;
    line-height: 1.2;
    border-radius: 3px;
    color: $txt-clr;
    background: $bg-clr;
  }
  //arrows
  &.p-tooltip-top   { .p-tooltip-arrow{ border-top-color:   $bg-clr; } }
  &.p-tooltip-right { .p-tooltip-arrow{ border-right-color: $bg-clr; } }
  &.p-tooltip-bottom{ .p-tooltip-arrow{ border-bottom-color:$bg-clr; } }
  &.p-tooltip-left  { .p-tooltip-arrow{ border-left-color:  $bg-clr; } }


  &.nowrap .p-tooltip-text { display: table ; white-space: nowrap; } // in use?
}
