.button-xs { height: $button-xs-height; font-size: $button-xs-font; }
.button-s  { height: $button-s-height; font-size: $button-s-font; }
.button    { height: $button-height; font-size: $button-font; }
.button-l  { height: $button-l-height; font-size: $button-l-font; }

.ta-icon {
  font-family: "ta-icons";
  font-style: normal;
  font-weight: normal;  
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button,
.btn,
.one-button { 
  transition: .3s;
}

.one-button {
  height: $button-xs-height;
  padding: $button-s-padding !important;
  border-radius: $button-border-radius;

  &:hover {
    color: #fff;
    background-color: #2d8abd;
    border-color: #2b82b2;
  }

  &.one-primary {
    background-color: var(--ta-primary-color);
    border: 1px solid var(--ta-primary-color);
    color: var(--ta-white-color);
    &:hover{ 
      background-color: var(--ta-primary-hover-color); 
      border: 1px solid var(--ta-primary-hover-color);
    }
  }

  &.one-back {
    @extend .one-primary;
    
    &::before {
      @extend .ta-icon;
      content: '\e286';
    }
  }

  &.one-back-article {
    @extend .one-primary;
  }

  &.one-cancel {
    background-color: var(--ta-white-color);
    border: 1px solid var(--ta-silver-dark-color);
    color: var(--ta-dark-color);
  }

  &.one-ghost {
    background-color: var(--ta-white-color);
    border: 1px solid #E6E6E7;
    color: var(--ta-text-color);

    &:hover:not(:disabled) {
      background: var(--ta-dropdown-panel-item-hover)
    }

    &:disabled {
      color: var(--ta-text-disabled-color);
      cursor: default;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.btn-primary,
.btn-primary:focus,
.btn-ta-one,
.btn-ta-one:focus,
.btn-primary.disabled, 
.btn-primary:disabled, 
.btn-primary.p-disabled{
  color: var(--ta-primary-text-color) !important;
  border: 1px solid var(--ta-primary-color) !important;
  border-radius: $button-border-radius !important;
  background-color: var(--ta-primary-color) !important;
  &:hover,
  &:active,
  &:visited,
  &:not(:disabled):not(.disabled):not(.p-disabled):active,
  &:not(:disabled):not(.disabled):not(.p-disabled).active,
  &.dropdown-toggle{
    color: var(--ta-primary-text-hover-color) !important;
    border: 1px solid var(--ta-primary-hover-color) !important;
    background-color: var(--ta-primary-hover-color) !important;
  }
}

.btn-info,
.btn-info:focus{
  color: var(--ta-info-text-color) !important;
  border-color: var(--ta-info-color) !important;
  background-color: var(--ta-info-color) !important;
  &:hover,
  &:active,
  &:visited,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.dropdown-toggle{
    color: var(--ta-info-text-hover-color) !important;
    border-color: var(--ta-info-hover-color) !important;
    background-color: var(--ta-info-hover-color) !important;
  }
}

// selectbutton // visible in search results + article overview
@mixin selectbutton($h, $p, $fz){ height:$h; padding:$p; font-size:$fz; }

.p-selectbutton{
  background: var(--ta-white-color);
  .p-button,button { 
    @include selectbutton($button-height,$buttongroup-padding,$button-font);
    color: var(--ta-text-color);
    border: 1px solid $button-ghost-border-color-grey !important;
    border-right-color: rgba(0,0,0,0) !important;
    background: none;
    transition:.3s;

    &:first-child{ 
      border-top-left-radius: $button-border-radius; 
      border-bottom-left-radius: $button-border-radius; 
    }
    &:last-child{ 
      border-right-color: $button-ghost-border-color-grey !important; 
      border-top-right-radius: $button-border-radius; 
      border-bottom-right-radius: $button-border-radius; 
    }
    //hover
    &:hover{background-color: var(--ta-dropdown-panel-item-hover);}
    //active
    &.p-highlight{
      color: var(--ta-white-color);
      border-color: var(--ta-primary-color) !important;
      background-color: var(--ta-primary-color);
      pointer-events: none;
    }
    //focus
    &:focus{
      border-color: var(--ta-primary-color) !important;
      box-shadow: 0 0 5px var(--ta-primary-color);
    }
  }  
  // sizing  
  &.p-selectbutton-xs{.p-button,button { @include selectbutton($button-xs-height,$buttongroup-xs-padding,$button-xs-font); } }
  &.p-selectbutton-s {.p-button,button { @include selectbutton($button-s-height,$buttongroup-s-padding,$button-s-font);    } }
  &.p-selectbutton-l {.p-button,button { @include selectbutton($button-l-height,$buttongroup-l-padding,$button-l-font);    } }
}
