.p-inline-message {
  border-radius: 3px;
  justify-content: left !important;
  margin: 0;
  padding: 0.429em;
  width: 100%;

  &.p-inline-message-error {
    background: var(--ta-danger-color);
    color: var(--ta-danger-text-color);
  }

  &.p-inline-message-warn {
    background: var(--ta-warning-color);
    color: var(--ta-warning-text-color);
  }

  &.p-inline-message-info {
    background: var(--ta-info-color);
    color: var(--ta-info-text-color);
  }

  &.p-inline-message-success {
    background: var(--ta-success-color);
    color: var(--ta-success-text-color);
  }
}