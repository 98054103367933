/*
  Form styles

  - h1, h2, h3...
  - input,
  - textarea
  - label, span, text
  - buttons
  - checkbox
  - dropdown
  - multiselect
  - rows (.one-form-row) (.one-report-checkbox-row)
  - columns
  - generic styles

*/

// #region // MARK: H1, H2, H3... 
h1 {
  &.page-title {
    display: flex; align-items: center;
    height: $input-height;
    margin: 0; padding-left: 5px;
    font-size: 20px;
    white-space: nowrap;
  }
}
// #endregion

// #region  MARK: INPUT
input {
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: $input-xs-height;
  padding: $input-xs-padding;
  border-radius: 0 !important;
  color: var(--ta-text-color);
  border: 1px solid var(--ta-input-border-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // &:focus {
  //   border: 1px solid var(--ta-text-color);
  //   border-radius: 0 !important;
  //   color: var(--ta-text-color);
  //   outline: 0;
  // }

  &:required {
    box-shadow: none;
  }

  &.input-xs {
    font-size: $input-xs-font;
    height: $input-xs-height;
    padding: $input-xs-padding;
  }

  &.input-s {
    font-size: $input-s-font;
    height: $input-s-height;
    padding: $input-s-padding;
  }

  &.input {
    font-size: $input-font;
    height: $input-height;
    padding: $input-padding;
  }

  &.input-l {
    font-size: $input-l-font;
    height: $input-l-height;
    padding: $input-l-padding;
  }

  &.error {
    border: 1px solid var(--ta-inline-error-background-color);
    color: var(--ta-inline-error-background-color);

    &:focus {
      color: var(--ta-text-color);
    }
  }

  &.warning {
    border: 1px solid var(--ta-warning-hover-color);
    color: var(--ta-warning-hover-color);

    &:focus {
      color: var(--ta-text-color);
    }
  }
}

/* 1.b. input[number] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
// #endregion

// #region  MARK: TEXTAREA 
textarea{
  border: 1px solid var(--ta-input-border-color);
  color: var(--ta-text-color);
  
  &.editable {
    resize: none;
  }

  &.input-xs {
    font-size: $input-xs-font;
    height: $input-xs-height;
    min-height: $input-xs-height;
    padding: $input-xs-padding;
    padding-top: 1px;
  }

  &.error {
    border: 1px solid var(--ta-inline-error-background-color);
    color: var(--ta-inline-error-background-color);

    &:focus {
      color: var(--ta-text-color);
    }
  }

  &.warning {
    border: 1px solid var(--ta-warning-hover-color);
    color: var(--ta-warning-hover-color);

    &:focus {
      color: var(--ta-text-color);
    }
  }  
}
// #endregion

// #region  MARK: LABEL, SPAN, TEXT
label { 
  word-break: break-word;
  &.label-min{ // placed over dropdown in title bar (settings > template article)
    font-size: 11px;
    margin: -9px 0 -3px 0;
  }
}

span,small {
  &.text-error,&.text-danger {
    display: block;
    color:  var(--ta-danger-color) !important; ; // var(--ta-inline-error-background-color);
    font-size: 11px;
    line-height: 13px;
    padding-top: 1px;
    padding-bottom: 1px;
    word-wrap: break-word;
  }

  &.text-warning {
    display: block;
    color: var(--ta-warning-hover-color) !important;
    font-size: 11px;
  }

  &.text-required {
    padding: 0 3px !important;
    color: var(--ta-danger-color);
  }
}

.text-ellipsis {
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// #endregion

// #region MARK: BUTTONS
button {
  transition: .3s;
  &.ghost {
    background-color: #fff;
    border: 1px solid var(--ta-primary-color);
    border-radius: $button-border-radius;
    color: var(--ta-primary-color);
    height: 25px;
    margin-left: 3px;
    outline: none;
    padding: 0 !important;
    width: 25px;

    &:disabled {
      border: 1px solid var(--ta-secondary-disabled-color);
      color: var(--ta-secondary-disabled-color);
      cursor: pointer;
    }

    &:hover {
      &:not([disabled]) {
        border: 1px solid var(--ta-primary-border-color);        
        color: var(--ta-white-color);
        background-color: var(--ta-primary-color);
      }
    }

    &.no-border {
      border: none;
      background-color: transparent;
  
      &:focus,&:hover {
        border-color: var(--ta-secondary-disabled-color) !important;
        box-shadow: none !important;
        border: none !important;
      }

      &.disabled {
        color: var(--ta-secondary-disabled-color) !important;
        border-color: var(--ta-secondary-disabled-color) !important;
        cursor: default !important;
        border: none !important;
      }
    }

    &.ghost-grow {
      display: inline-flex;
      flex: 0 0 25px;
      width: inherit;
      padding: 3px !important;
    }

    &.input-border-color {border-color: var(--ta-input-border-color); }

    &.attached-to-right{
      margin-left: 0;
      border-left: none;      
      border-radius: 0 $button-border-radius $button-border-radius 0;
      
      &:hover{
        &:not([disabled]) {
          border-left: none;
        }
      }
    }

    &.only-icon{
      background-color: none;
      &:hover {
        &:not([disabled]) {      
          color: var(--ta-white-color);
          background-color: var(--ta-primary-color);
        }
      }
    }
  }
}
// #endregion

// #region MARK: CHECKBOX 
p-checkbox {
  &.error {
    .p-checkbox-box { border-color: var(--ta-inline-error-background-color) !important; }
  }
  .p-checkbox {
    .p-hidden-accessible{ 
      position: relative !important; 
    }
    // .p-checkbox-box {
    //   &.p-state-disabled { }
    //   .p-checkbox-icon { }
    // }
  }
}
// #endregion

// #region MARK: RADIO
input[type=radio]{
  width: $radio-height;
  height: $radio-height;
  margin-right: .5rem;
  cursor: pointer;
  accent-color: var(--ta-primary-color); // checked
}
// #endregion

// #region MARK: DROPDOWN
p-dropdown {
  &.error {
    .p-dropdown {
      border-color: var(--ta-inline-error-background-color) !important;
      color: #757575 !important;
    }
  }

  &.warning {
    .p-dropdown {
      border-color: var(--ta-warning-hover-color) !important;
    }
  }

  .p-dropdown {
    display: flex;
    border: 1px solid var(--ta-input-border-color);
    border-radius: 0 !important;
    font-size: var(--ta-font-size);

    .p-dropdown-label {
      display: block !important;
    }

    &.dropdown-xs {
      // height: $dropdown-xs-height;
      // padding: $dropdown-xs-padding;

      .p-dropdown-label-container {
        .p-dropdown-label {
          // height: $dropdown-xs-height - 2px !important;
          // padding: $dropdown-xs-padding !important;
        }
      }

      .p-dropdown-panel {
        .p-dropdown-filter-container {
          input.p-dropdown-filter {
            height: $input-xs-height;
          }
        }

        // .p-dropdown-items-wrapper {
        //   .p-dropdown-items {
        //     .p-dropdown-item {
        //       padding: 0.2em 0.857em;
        //     }
        //   }
        // }
      }
    }

    .p-dropdown-panel {
      .p-dropdown-filter-container {
        background-color: #eceeef !important;
      }

      // .p-dropdown-items-wrapper {
      //   .p-dropdown-items {
      //     .p-dropdown-item {
      //       color: var(--ta-text-color);
      //       &:hover {
      //         background-color: #eceeef !important;
      //       }
      //       &.p-state-highlight {
      //         background-color: var(--ta-primary-color) !important;
      //         color: #fff !important;
      //       }
      //     }
      //   }
      // }
    }

    .p-dropdown-label-container {
      .p-dropdown-label {
        color: var(--ta-text-color) !important;
      }
    }
  }
}
// #endregion

// #region MARK: MULTISELECT
p-multiselect {
  &.error {
    .p-multiselect {
      border-color: var(--ta-inline-error-background-color) !important;
      color: #757575 !important;
    }
  }

  &.warning {
    .p-multiselect {
      border-color: var(--ta-warning-hover-color) !important;
    }
  }

  .p-multiselect {

    &.multiselect-xs {
      .p-multiselect-label-container {
        .p-multiselect-label {
          height: $multiselect-xs-height - 2px !important;
          line-height: $multiselect-xs-height - 2px !important;
          padding: $multiselect-xs-padding !important;
        }
      }

      input.p-multiselect-filter {
        height: $input-xs-height;
      }
    }

    &.multiselect-s {
      .p-multiselect-label-container {
        .p-multiselect-label {
          height: $multiselect-s-height - 2px !important;
          line-height: $multiselect-s-height - 2px !important;
          padding: $multiselect-s-padding !important;
        }
      }

      input.p-multiselect-filter {
        height: $input-xs-height;
      }
    }

    // &.p-disabled {
    //   .p-multiselect-label {
    //     background: var(--ta-light-color) !important;
    //     color: var(--ta-text-disabled-color) !important;
    //   }
    // }
  }

  .p-multiselect-label-container {
    .p-multiselect-label {
      color: var(--ta-text-color);
    }
  }

  .p-multiselect-panel {
    .p-multiselect-header {
      .p-multiselect-filter-container {
        input.p-inputtext {
          color: var(--ta-text-color);
        }
      }
    }

    .p-multiselect-items-wrapper {
      .p-multiselect-items {
       .p-multiselect-empty-message {
        color: var(--ta-text-color);
       }
      }
    }
  }
}
// Dropdown + Multiselect // No results Message
.p-dropdown-empty-message, .p-multiselect-empty-message{ padding: .5em; color: var(--ta-text-color); }
// Hack to attach dropdowns to the right of parents in right sidebars
.p-sidebar .p-dropdown-panel, #sidebar-right-bar .p-dropdown-panel, .p-sidebar .p-multiselect-panel, #sidebar-right-bar .p-multiselect-panel{ left: auto !important; right: -1px; }

// #endregion

// #region MARK: ROWS 

// ROWS IN MAINTENANCE SIDEBAR FORMS
.one-form-row {
  display: flex;
  align-items: flex-start;
  min-height: 25px;
  margin-bottom: 2px;

  &>div:first-child {
    // label box, usually .flex-40
    display: flex;
    align-items: flex-start;
    padding-right: 5px;

    span:first-child:not(.p-checkbox-icon) {
      // label
      margin: 0;
      padding: 5px 3px 5px 0;
      line-height: 15px;
      text-align: left;
      word-break: break-word;
      white-space: break-spaces;
    }
  }

  p-checkbox {
    .p-checkbox { padding-top: 4px; }
    label.p-checkbox-label, //default primeng label
    +span                   // labels in settings, reporting
      {
      padding-top: 6px;
      line-height: 15px;
    }
  }
  // radio + label box 
  .one-radio-wrap{
    display: flex;
    align-items: center !important;
    margin-right: 1rem;
    input[type=radio]{}
    label{ margin: 0; white-space: nowrap;}
  }
}

// #endregion

// #region MARK: COLUMNS
.flex-5   { flex: 0 0 5%;  max-width: 5%;}
.flex-10  { flex: 0 0 10%; max-width: 10%;}
.flex-15  { flex: 0 0 15%; max-width: 15%;}
.flex-20  { flex: 0 0 20%; max-width: 20%;}
.flex-25  { flex: 0 0 25%; max-width: 25%;}
.flex-30  { flex: 0 0 30%; max-width: 30%;}
.flex-35  { flex: 0 0 35%; max-width: 35%;}
.flex-40  { flex: 0 0 40%; max-width: 40%;}
.flex-45  { flex: 0 0 45%; max-width: 45%;}
.flex-50  { flex: 0 0 50%; max-width: 50%;}
.flex-55  { flex: 0 0 55%; max-width: 55%;}
.flex-60  { flex: 0 0 60%; max-width: 60%;}
.flex-65  { flex: 0 0 65%; max-width: 65%;}
.flex-70  { flex: 0 0 70%; max-width: 70%;}
.flex-80  { flex: 0 0 80%; max-width: 80%;}
.flex-90  { flex: 0 0 90%; max-width: 90%;}
.flex-95  { flex: 0 0 95%; max-width: 95%;}
.flex-100 { flex: 0 0 100%; max-width: 100%;}
// #endregion

// #region MARK: GENERIC STYLES

// Placeholder
::-webkit-input-placeholder  { color: var(--ta-placeholder-color) !important; }  /* Edge */
:-ms-input-placeholder { color: var(--ta-placeholder-color) !important; }       /* Internet Explorer 10-11 */
::placeholder  { color: var(--ta-placeholder-color) !important; }
.p-placeholder { color: var(--ta-placeholder-color) !important; }

// DISABLED (form elem + buttons)
.disabled,
[disabled],
.p-disabled:not(.p-accordion-header) { 
  cursor: default !important; 
  pointer-events: none !important;
  opacity: var(--ta-form-elem-disabled-opacity) !important; 
  background: var(--ta-form-elem-disabled-bgcolor);
}
// #endregion
