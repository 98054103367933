.p-chips-token { // li
  position: relative;
  display: inline-block;
  margin: 0 0.286em 0 0;
  padding: 0.2145em 0.429em;
  color: #fff;
  font-size: 13px ;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 3px;
  background-color: var(--ta-text-color) ;
  overflow: hidden;
  transition: background .3s;

  &:hover{
    background-color: var(--ta-primary-hover-color);
  }

  &>div{
    margin-right: 7px;
  }

  .p-chips-token-icon{
    &:before{
      font-family: ta-icons;
      content: '\e110';
    }
  }
}