@import "variables";
@import "variables2";

/*
BASE
CUSTOM UTILITIES
OVERRIDES
FOCUS / NO FOCUS
ANIMATIONS
MAINTENANCE GRIDS
MISC
*/

//#region MARK: BASE
html,
body {
  color: var(--ta-text-color);
  font-family: "Roboto", Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  min-height: 100vh;
  min-width: 320px;  
}

a.link, a[href] {
  color: var(--ta-text-color);
  text-decoration: none !important;
  &:hover { color: var(--ta-text-hover-color); cursor: pointer; }
  &:focus { color: var(--ta-text-hover-color); }
  &.disabled { color: var(--ta-text-disabled-color); }
}
//#endregion

// #region MARK: CUSTOM UTITLITIES
.ellipsis{ display:block; width: 100%; max-width: 100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; } // custom
.line-break-anywhere { line-break: anywhere; }
.cursor-pointer { cursor: pointer; }
.pr2 { padding-right: 2px !important; } // in pixels instead of bootstrap .pr-2 that is in em // custom
.mb2 { margin-bottom: 2px !important; } // in pixels instead of bootstrap .mb-2 that is in em // custom
.font-11{font-size: 11px !important;}
.font-12{font-size: 12px !important;}
.font-13{font-size: 13px !important;}
.font-14{font-size: 14px !important;}
.font-15{font-size: 15px !important;}
.font-16{font-size: 16px !important;}
.font-17{font-size: 17px !important;}
.font-18{font-size: 18px !important;}
.font-19{font-size: 19px !important;}
.font-20{font-size: 20px !important;}

// FIXED HEIGHT BOXES (same heights as button, inputs, etc)
[class*="box-height"] { display: flex; align-items: center; }
.box-height-xs{ height: $box-xs-height;}
.box-height-s { height: $box-s-height;}
.box-height   { height: $box-height;}
.box-height-l { height: $box-l-height;}
//#endregion

// #region MARK: OVERRIDES 
// BOOTSTRAP 4 OVERRIDES
a.text-primary {color: var(--ta-primary-color) !important; &:hover{color: var(--ta-primary-hover-color)!important; }} 
.text-info-color {color: var(--ta-info-color);}
.rounded{border-radius: 3px !important;}  
.font-weight-bold   {font-weight: 500 !important;} 
.table{color: var(--ta-text-color);}
.row { margin-right: -5px; margin-left: -5px; 
  &.no-gutters{margin-right: 0;margin-left: 0;} 
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto
{ padding-right: 5px; padding-left: 5px; }

// FONTAWESOME OVERRIDES
.fa:before { margin: 0 0.2em; } // align fontawesome with ta-icons margins // fontawesome

// #endregion

// #region MARK: FOCUS / NO FOCUS
*:focus{outline: none !important;}

a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable=true],
.btn,.button,.button-sx,.button-s,.button-l,
.p-dropdown,.dropdown,.dropdown-sx,.dropdown-s,.dropdown-l,
//AGGRID FOCUS
.ag-theme-balham input[class^='ag-']:not([type]),
.ag-theme-balham input[class^='ag-'][type='text'],
.ag-theme-balham input[class^='ag-'][type='number'],
.ag-theme-balham input[class^='ag-'][type='tel'],
.ag-theme-balham input[class^='ag-'][type='date'],
.ag-theme-balham input[class^='ag-'][type='datetime-local'],
.ag-theme-balham textarea[class^='ag-']
{
  &:focus {
    border-color: rgba(#000000, 0);
    box-shadow: 0 0 0 1px var(--ta-primary-color), 
                0 0 5px var(--ta-primary-color);  
    z-index: 1;  
  }
}
.one-button.one-primary, 
.one-button.one-back{ &:focus{border: 1px solid rgba(#fff, .7);} }

// DROPDOWN + MULTISELECT BOX FOCUS
.p-dropdown.p-inputwrapper-focus .p-dropdown,
.p-inputwrapper.p-inputwrapper-focus .p-multiselect,
.p-inputwrapper.p-inputwrapper-focus.p-focus,
.p-inputwrapper-focus:not(.p-element){
  border-color: var(--ta-primary-color) !important;
  box-shadow: 0 0 5px var(--ta-primary-color) !important;
}
  // DROPDOWN + MULTISELECT ITEMS FOCUS
  li.p-dropdown-item.p-focus,
  li.p-multiselect-item.p-focus{ background-color: var(--ta-dropdown-panel-item-hover) !important;}

// SPLITBUTTON FOCUS
.p-splitbutton .p-splitbutton-menubutton,
.p-splitbutton .p-splitbutton-defaultbutton{
  &:focus{
    box-shadow: 0 0 0 1px var(--ta-white-color), 
                0 0 5px var(--ta-primary-color);
  }
} 

// TABS FOCUS
.p-tabview-nav-link:focus{ box-shadow: 0 2px 10px 1px var(--ta-primary-color); }

// OMNISEARCH FOCUS
#searchOmnibox input.form-control:not([disabled]):focus{
  border-width: 1px;
  border-color: var(--ta-primary-color);
  box-shadow: 0 0 5px var(--ta-primary-color);
}

/* NO FOCUS */
/* NO FOCUS */
/* NO FOCUS */
.ag-body-viewport *, // ag-grid content
.p-panel.ta-one-panel a.next, .p-panel.ta-one-panel a.prev, // ag-grid top paginator
input[type=radio],
.p-dropdown-label,
.p-tieredmenu-root-list // splitbutton dropdown panel
{
  &:focus{ box-shadow: none; }
}
// #endregion

// #region MARK: ANIMATIONS
// blink
@keyframes blinkTwice { 50% {opacity: 0;} }  
.one-blinkTwice{animation: blinkTwice .5s 2;}
// #endregion

// #region MARK: MAINTENANCE GRIDS

// SHOW/HIDE NOT 'ALWAYS VISIBLE' FIELDS 
// ATTRIBUTES IN AO AND MAINTENANCE GRIDS
// button active
.areAllAttributesVisible {
  position: relative;
  color: var(--ta-white-color) !important;
  background-color: var(--ta-primary-color) !important;
  &::after{
    content: ":";
    position: absolute; top: 11px; left: 3px;
    font-family: 'Roboto';
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
  }
}
// label active
label.isNotIntrinsicallyVisible {
  &::before {
    content: "·";
    position: relative; top: 0; left: -4px;
    margin-right: -3px;
    font-size: 16px;
    font-weight: bold;
    color: var(--ta-primary-color);
  }
}
// #endregion


// #region MARK: MISC
// VERTICAL LINE SEPARATOR | BETWEEN PAGE SELECTOR AND PAGE OPTIONS (i.e. SETTINGS > PAGE SELECTOR | OPTIONS)
.page-header-separator {
  width: 1px;
  height: 25px;
  margin: 0 10px;
  background-color: var(--ta-input-border-color);
}

// DEFAULT DRAG & DROP ICON STYLE
.ta-icon-drag-drop { color: var(--gray-dark); cursor: grab;
  &:hover { color: var(--ta-primary-color); }
}

// #endregion
