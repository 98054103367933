.p-sidebar-right.p-sidebar-md {
  width: 30em !important;
}

.sidebar {

  .sidebar-body {

  }
  .sidebar-footer {

  }
}