@font-face { 
  font-family: 'ta-spinner';
  src: url(data:application/octet-stream;base64,d09GMgABAAAAAAIwAAsAAAAABJAAAAHiAAGZmgAAAAAAAAAAAAAAAAAAAAAAAAAABmAAVBEICoEwgUkBNgIkAxQLDAAEIAWDKgcgG9wDyC4HeDJMniFU7O30/NJawQkjoOJ/WmhuBMXS7d3/p3wopalYqkscVVg0RpQmVCzG4AxC0sdnkLFkTO/TKhYGFQGPbQHYT01IFA4sxm+65AYIG3Aa6CCTjDgufAAkjxltBiou9v9F//j5vxm3dAN/lQjbwAkFOp8HHkhCc9CkusGnh0dzqNv+cng5ezIsiWpJGoyCwRaKFANUxFUETsNzKKcINjFl7j7+kCCBAMAWoECBNaQMQ0xhOgG1lpTC1p/RAXchRotZCACEGJkEFahRYh/0AfYARCoF8G5s/8brBk9rVluyS9H2+7XgEk+am43jgb3raae7I6LrrfMRAOj9p7Gib6/f33PFx2Lj0JmtxsD/TmPxeiKR1bfF8v1eNB6vRbP7qR3ZKsUCgFQ4/xGI1/PvV5bnG9JJ8PHWfJYbp4SWBEHCnAEsqvQcCYFKF4Ek+zDhDQFCjQCp6ZZAodZDoFQbIEClYwOBhto1gdqKWxSibAtzWARpwgsozElAJXYOKgsUoGFO3ad24GVS1IwHj922wqSoMR40e73qaM1+HwatFSfV0bjZ70kYaFlnbTTE+14HhYNqfdop9nNHSN3mpibK/e8OJiDa700k6tXBDsVJtSJRWkokyv1M39xrMQAAAAA=);
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ta-spinner{
  display: inline-block; 
  margin-top: -1px;
  font-family: 'ta-spinner' !important;
  font-style: normal!important; 
  font-weight: normal!important; 
  font-variant: normal!important; 
  text-transform: none!important;
  line-height: 1; 
  vertical-align: middle;
  animation: ta-anim-spin 1.5s infinite linear; 

  &:before{ content: '\e900'; }
}

@keyframes ta-anim-spin { 
  0%   { transform: rotate(0deg); } 
  100% { transform: rotate(359deg); } 
}